import { useParams } from "react-router-dom";
import { InvoiceForm } from "../../invoice/Invoice"
import { useEffect, useState } from "react";
import { Invoice } from "../../invoice/Invoice.types";
import { getInvoiceAPI } from "../../invoice/services/createInvoiceService/getInvoice.service";
import ErrorMessage from "../../common/ErrorMessage";
import { InvoiceView } from "../../invoice/InvoiceView";

export const DashboardInvoiceView = () => {

    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [generalError, setGeneralError] = useState<string | null>(null); // For general error handling

    const {id} = useParams<{id: string}>();

    useEffect(() => {
        if (!id) {
            setGeneralError("Nie znaleziono takiej faktury.");
            return;
        }
        const fetchInvoice = async () => {
            const invoiceResponse = await getInvoiceAPI(id).catch((error) => {
                setGeneralError("Nie znaleziono takiej faktury.");
                return;
            })
            invoiceResponse && setInvoice(invoiceResponse);
        }
        fetchInvoice();
        console.log(invoice)
    }, []);

    return (
        <>
            {/* todo: replace InvoceForm with some readonly view component */}
            {!invoice ? (<ErrorMessage message={generalError} />) : (<InvoiceView invoiceData={invoice} />)}
        </>
    )
}