import Cookies from "js-cookie";
import { config } from "../../../../config/config";
import { adaptInvoiceAPIResponse } from "./getInvoicesByUser.service";

export const getInvoiceAPI = async (id: string) => {
  try {
    const userToken = Cookies.get('userToken');
    const response = await fetch(`${config.API_URL}/invoices/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    });

    if (response.ok) {
      const responseJson = await response.json();
      if (!responseJson) {
        throw Error ('No invoice');
      }
      const invoice = adaptInvoiceAPIResponse(responseJson);
      return invoice;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    console.error('Error getting invoices by user:', (error as Error).message);
    throw error;
  }
};
