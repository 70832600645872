import { TextContenChild } from "../TextContent.type";

export const Title = ({ children }: TextContenChild) => {
    return (
        <header className="mb-4 lg:mb-6 not-format">
            <h1 className="
                mb-4 
                text-6xl 
                font-extrabold 
                text-gray-900 
                lg:mb-6 lg:text-4xl 
                dark:text-white">
                    {children}
            </h1>
        </header>
    );
}