import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./services/RegisterService/Register.types";



const userSlice = createSlice({
    name: 'auth',
    initialState: {} as User,
    reducers: {
        setUser: (_state, action: PayloadAction<User>) => {
            return action.payload;
        }
    }
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer; 