import { configureStore } from "@reduxjs/toolkit";
import invoiceSlice from "./components/invoice/invoiceSlice";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserSlice from "./components/auth/UserSlice";

export const store = configureStore({
    reducer: {
        invoice: invoiceSlice,
        user: UserSlice
    },
    // Enable DevTools in non-production environments
    devTools: process.env.NODE_ENV !== 'production', 
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;