export type ValidationResult = {
    error: string | false;
};

export const validateEmail = (email ?: string): ValidationResult => {
    if (!email) {
        return { error: 'Adres email jest wymagany.'};
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        return { error: 'Niepoprawny adres email. Sprawdź i spróbuj ponownie.' };
    }
    return { error: false };
}