import Cookies from "js-cookie";
import { config } from "../../../../config/config";
import { Invoice } from "../../Invoice.types";
import { toCents } from "../../../../utils/Numbers.util";

export const createInvoiceRequest = async ({ invoice, token, metaData }: { invoice: Invoice, token: string , metaData?: Record<string, boolean>}) => {
  const userId = Cookies.get('userId');
  const adaptedInvoice = prepareRequestBody(invoice);
  const requestBody = {
    ...adaptedInvoice,
    ...(metaData ? {metaData} : {})
  }
  try {
    const response = await fetch(`${config.API_URL}/users/${userId}/invoices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    console.error('Error registering user:', (error as Error).message);
    throw error;
  }
};

const prepareRequestBody = (invoice: Invoice) => {
  return {
    ...invoice,
    seller_line_1: invoice.from?.line1,
    seller_line_2: invoice.from?.line2,
    seller_line_3: invoice.from?.line3,
    buyer_line_1: invoice.to?.line1,
    buyer_line_2: invoice.to?.line2,
    buyer_line_3: invoice.to?.line3,
    totalGross: toCents(invoice.totalGross),
    totalNet: toCents(invoice.totalNet),
    totalTax: toCents(invoice.totalTax),
    items: [...invoice.items.map(item => {
      return {
        ...item,
        quantity: item.quantity ? parseFloat(item.quantity) : 0,
        price: toCents(item.price),
        amountNet: toCents(item.amountNet),
        amountGross: toCents(item.amountGross),
        taxRate: toCents(item.taxRate),
        taxAmount: toCents(item.taxAmount),
      }
  })]
  }
};
