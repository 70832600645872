import Cookies from "js-cookie";
import { config } from "../../../../config/config";

// attempts to retrieve auth token from cookies and get user data for it
export const getLoggedInUserData = async () => {
    try {
        const userToken = Cookies.get('userToken');
        const response = await fetch(`${config.API_URL}/users`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
            },
        });

        if (response.ok) {
            const responseJson = await response.json();
            return responseJson;
        } else {
            const errorData = await response.json();
            throw new Error(errorData.message);
        }

    } catch (error: unknown) {
        // Handle errors
        console.error('Error getting logged user info:', (error as any).message);
        throw error;
    }
}