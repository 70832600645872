
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';
import LogoImage from '../../../assets/instafakt-logo-faktury-online.png'

export const SiteFooter = () => {
    return (
        <>
        <div className="container mx-auto p-4">

        <Footer container>
            <div className="w-full">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div>
                        <Footer.Brand
                            href="/"
                            src={LogoImage}
                            alt="Program do faktur online"
                            name="Program do faktur online"
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-4 sm:mt-4 sm:grid-cols-4 sm:gap-3">
                        <div>
                            <Footer.Title title="Baza wiedzy" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/article/faktury-online">Dlaczego faktury online</Footer.Link>
                                <Footer.Link href="/article/faktura-wzor">Wzór faktury</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="O nas" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="/">InstaFakt</Footer.Link>
                                <Footer.Link href="/">Kontakt</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Follow us" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="#">Twitter</Footer.Link>
                                <Footer.Link href="#">Facebook</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Legal" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="#">Prywatność</Footer.Link>
                                <Footer.Link href="#">Regulamin</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright href="#" by="InstaFakt" year={2024} />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon href="#" icon={BsFacebook} />
                        <Footer.Icon href="#" icon={BsInstagram} />
                        <Footer.Icon href="#" icon={BsTwitter} />
                        <Footer.Icon href="#" icon={BsGithub} />
                        <Footer.Icon href="#" icon={BsDribbble} />
                    </div>
                </div>
            </div>
        </Footer>
        </div>
        </>
    );
}
