export type DateFormat = `${number}-${string}-${string}`;


// takes any string date, eg '2023-10-23T00:00:00.000Z and produces YYYY-MM-DD
export const niceDate = (inputDate: string): DateFormat => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth is zero-based
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export const todayDate = (): DateFormat => {
  const date = new Date();
  
  const year = Number(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate: DateFormat = `${year}-${month}-${day}`;
  return formattedDate;
}


export const compareDates = (date1: DateFormat, date2: DateFormat): number => {
  const [year1, month1, day1] = date1.split('-').map(Number);
  const [year2, month2, day2] = date2.split('-').map(Number);

  if (year1 !== year2) {
    return year1 - year2;
  } else if (month1 !== month2) {
    return month1 - month2;
  } else {
    return day1 - day2;
  }
  // if (comparison < 0) date1 is before date2
  // if (comparison > 0) date1 is after ${date2
  // else date1 is the same as date2
}

export const addDaysToDate = (date: DateFormat, days: number): DateFormat => {
  const [year, month, day] = date.split('-').map(Number);
  const currentDate = new Date(year, month - 1, day); // month - 1 because months are 0-indexed in JavaScript

  const newDate = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);
  const newYear = newDate.getFullYear();
  const newMonth = String(newDate.getMonth() + 1).padStart(2, '0');
  const newDay = String(newDate.getDate()).padStart(2, '0');

  return `${newYear}-${newMonth}-${newDay}` as DateFormat;
}