import Cookies from 'js-cookie';

export const logout = () => {

    try {
        Cookies.remove('userId');
        Cookies.remove('userToken');
    } catch (error: unknown) {
        // Handle errors
        console.error('Error logging out:', (error as any).message);
        throw error;
    }
};