
export const handleApiResponseError = (error: any): string => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data?.message || 'Wystąpił błąd. Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    } else if (error.request) {
      // The request was made but no response was received
      return 'Brak odpowiedzi serwera.Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    } else {
      // Something happened in setting up the request that triggered an Error
      return 'Wystąpił błąd. Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    }
  };