import Cookies from "js-cookie";
import { config } from "../../../../config/config";
import { amountFromCents, stripTrailingZeros } from "../../../../utils/Numbers.util";
import { Invoice, InvoiceItem } from "../../Invoice.types";

export const getInvoicesByUserRequest = async () => {
  try {
    const userId = Cookies.get('userId');
    const userToken = Cookies.get('userToken');
    const response = await fetch(`${config.API_URL}/users/${userId}/invoices`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    });

    if (response.ok) {
      const responseJson = await response.json();
      const invoicesList = responseJson.map((invoice: Invoice) => adaptInvoiceAPIResponse(invoice));
      return invoicesList;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    console.error('Error getting invoices by user:', (error as any).message);
    throw error;
  }
};


export const adaptInvoiceAPIResponse = (invoiceInResponse: any): Invoice => {
  return {
    ...invoiceInResponse,
    from: {
      line1: invoiceInResponse.seller_line_1 || '',
      line2: invoiceInResponse.seller_line_2 || '',
      line3: invoiceInResponse.seller_line_3 || '',
    },
    to: {
      line1: invoiceInResponse.buyer_line_1 || '',
      line2: invoiceInResponse.buyer_line_2 || '',
      line3: invoiceInResponse.buyer_line_3 || '',
    },
    dueDate: invoiceInResponse.dueAt || null,
    totalGross: amountFromCents(invoiceInResponse.totalGross),
    totalNet: amountFromCents(invoiceInResponse.totalNet),
    totalTax: amountFromCents(invoiceInResponse.totalTax),
    items: [...(invoiceInResponse.Items ?? []).map((item: any) => {
      return {
        ...item,
        price: amountFromCents(item.price),
        quantity: stripTrailingZeros(item.quantity),
        amountNet: amountFromCents(item.amountNet),
        amountGross: amountFromCents(item.amountGross),
        taxRate: amountFromCents(item.taxRate),
        taxAmount: amountFromCents(item.taxAmount),
      }
    })]
  }
};