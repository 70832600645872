
import { Button, Card } from "flowbite-react";
import { SiteNavbar } from "../layout/Navbar/SiteNavbar";
import { SiteFooter } from "../layout/Footer/SiteFooter";
import { HeaderSection } from "../layout/Public/HeaderSection/HeaderSection";

export function PricingPage() {

  return (
    <>
      <SiteNavbar />
      <HeaderSection heading='Cennik' subheading='Wybierz ofertę dla siebie.' />
      <div className="container mx-auto p-4">
        <div className="flex justify-center space-x-36 py-8">

        <Card>
          <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">PROSTY</h5>
          <div className="flex items-baseline text-gray-900 dark:text-white">
            <span className="text-5xl font-extrabold tracking-tight  text-sky-500">0 zł</span>
            <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/mies.</span>
          </div>
          <ul className="my-7 space-y-5">
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">1 faktura/mies.</span>
            </li>
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                Nielimitowani klienci
              </span>
            </li>
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Pobieranie i drukowanie faktur</span>
            </li>
            <li className="flex space-x-3 decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Wysyłanie faktur e-mailem</span>
            </li>
            <li className="flex space-x-3 line-through decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-gray-400 dark:text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Raporty</span>
            </li>
            <li className="flex space-x-3 decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-gray-400 dark:text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Odnośnik do Instafakt</span>
            </li>
          </ul>
          <Button
            href="/rejestracja?plan=1"
            className="inline-flex w-full hover:bg-transparent justify-center rounded-lg bg-transparent px-5 py-2.5 text-center text-sm font-medium text-sky-800 outline outline-2 outline-sky-500 "
          >
            Wybierz
          </Button>
        </Card>

        <Card className="border-2 border-sky-500">
          <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">PREMIUM</h5>
          <div className="flex items-baseline text-sky-800 dark:text-white">
            <span className="text-5xl line-through font-bold  text-gray-400 tracking-tight">19 zł</span>
            <span className="text-5xl font-extrabold tracking-tight text-sky-500 px-2">5 zł</span>
            <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/mies.</span>
          </div>
          <ul className="my-7 space-y-5">
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-bold leading-tight text-gray-500 dark:text-gray-400">Nielimitowane faktury/mies.</span>
            </li>
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                Nielimitowani klienci
              </span>
            </li>
            <li className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Pobieranie i drukowanie faktur</span>
            </li>
            <li className="flex space-x-3 decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Wysyłanie faktur e-mailem</span>
            </li>
            <li className="flex space-x-3 decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Raporty</span>
            </li>
            <li className="flex space-x-3 decoration-gray-500">
              <svg
                className="h-5 w-5 shrink-0 text-sky-500 dark:text-sky-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500">Bez odnośników do InstaFakt</span>
            </li>
          </ul>
          <Button
            href="/rejestracja?plan=2"
            className="inline-flex w-full justify-center rounded-lg bg-sky-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
          >
            Wybierz
          </Button>
        </Card>
        
      </div>

    </div>
      <SiteFooter />
      </>
  );
}
