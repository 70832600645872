import { Route, Routes, useNavigate } from "react-router-dom"
import { DashboardNavbar } from "./Navbar/DashboardNavbar"
import { DashboardSidebar } from "./Sidebar/DashboardSidebar"
import { DashboardInvoiceList } from "./InvoiceList/InvoiceList"
import { DashboardHome } from "./Home/DashboardHome"
import { useAppDispatch, useAppSelector } from "../../store"
import { User } from "../auth/services/RegisterService/Register.types"
import { useEffect } from "react"
import { setUser } from "../auth/UserSlice"
import { getLoggedInUserData } from "../auth/services/getLoggedUserData/getLoggedInUserData.service"
import { DashboardInvoice } from "./Invoice/DashboardInvoice"
import { DashboardInvoiceView } from "./Invoice/DashboardInvoiceView"



export const Dashboard = () => {

    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validatateUserAccess = async (user?: User) => {
        // if no user data exists, attempt to get it from API call again (uses auth token from cookies)
        if (!user?.email || !user?.token) {
            const refreshedUserInfo = await getLoggedInUserData();
            
            if (!refreshedUserInfo.email) {
                navigate('/logowanie');
                return;
            }
            dispatch(setUser(refreshedUserInfo))
        }
    }

    useEffect(() => {
        validatateUserAccess(user);
    }, []);

    return (
        <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5" >
            <DashboardNavbar></DashboardNavbar>

            <aside className="float-left">
                <DashboardSidebar ></DashboardSidebar>
            </aside>
            <div className="p-4 sm:ml-64">
                <Routes>
                    <Route index element={<DashboardHome />} />
                    <Route path="invoices" element={<DashboardInvoiceList />} />
                    <Route path="invoices/create" element={<DashboardInvoice />} />
                    <Route path="invoices/:id" element={<DashboardInvoiceView />} />
                </Routes>
            </div>
        </section>

    )
}