import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

function useQueryParam(paramName: string): string | null {
  const [searchParams] = useSearchParams();
  const [paramValue, setParamValue] = useState<string | null>(null);

  useEffect(() => {
    const paramString = searchParams.get(paramName);
    if (paramString !== null) {
      const paramNumber = String(paramString);
      setParamValue(paramNumber);
    }
  }, [searchParams, paramName]);

  return paramValue;
}

export default useQueryParam;