import { config } from "../../../../config/config";
import { RegisterInput, User } from "./Register.types";
import Cookies from 'js-cookie';

export const register = async (input: RegisterInput) => {
  try {
    const response = await fetch(`${config.API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    });

    if (response.ok) {
      // Registration successful
      const userAuthenticated = await response.json() as User;
      // Set the token in cookies for using in subsequent requests. set an expiration of 7 days
      Cookies.set('userId', userAuthenticated.userId, { expires: 7 })
      Cookies.set('userToken', userAuthenticated.token, { expires: 7 })
      return userAuthenticated;
    } else {
      // Registration failed
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    console.error('Error registering user:', (error as any).message);
    throw error;
  }
};