import { Link, useParams, useNavigate } from "react-router-dom";
import { Paragraph } from "./Paragraph/Paragraph";
import { Subheading } from "./Subheading/Subheading";
import * as articlesData from '../article/articles-data/articles.json';
import { useEffect, useState } from "react";
import { Title } from "./Title/Title";
import { Breadcrumb, Button } from 'flowbite-react';
import { SiteNavbar } from "../layout/Navbar/SiteNavbar";
import { SiteFooter } from "../layout/Footer/SiteFooter";
import { HiHome } from "react-icons/hi";
export const Article = () => {

    // get slug the current url:  domain/article/title
    const { title } = useParams<{title: 'faktury-online' | 'faktura-wzor'}>();
    const navigate = useNavigate();

    const [ articleContent, setArticleContent ] = useState([]);
    
    useEffect(() => {
        if (!isUrlSupported(title)) {
            navigate('/');
        } else {
            //@ts-ignore
            setArticleContent(articlesData[title]);
        }
    }, [title, navigate]);

    useEffect(() => {
        //@ts-ignore
        document.title = articleContent?.[0]?.Title || '' + ' | Instafakt.pl';
    
        return () => {
          document.title = 'Instafakt'; // Cleanup or reset title when component unmounts
        };
      }, [articleContent]);

    return (
        <>
            <SiteNavbar />
            <div className="container mx-auto p-4">

                <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
                    <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                        
                        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                            <Breadcrumb aria-label="Baza wiedzy breadcrumbs" className="my-4">
                                <Breadcrumb.Item href="/" icon={HiHome}>Instafakt</Breadcrumb.Item>
                                <Breadcrumb.Item href="/article">Baza Wiedzy</Breadcrumb.Item>
                             </Breadcrumb>
                            {//@ts-ignore
                            articleContent.map(articlePart => {
                            //@ts-ignore
                                if (Object.keys(articlePart)[0] === 'Title') return (<Title>{articlePart.Title}</Title>)
                            //@ts-ignore
                                if (Object.keys(articlePart)[0] === 'Subheading') return (<Subheading>{articlePart.Subheading}</Subheading>)
                            //@ts-ignore
                                if (Object.keys(articlePart)[0] === 'Paragraph') return (<Paragraph>{articlePart.Paragraph}</Paragraph>)
                                return (<></>)
                            })}

                            <Link to="/faktura-formularz"> 
                                <Button className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">Stwórz fakturę online</Button>
                            </Link>
                        </article>    
                    </div>
                </main>
            </div>
          <SiteFooter />

        </>
    );
}

const isUrlSupported = (url?: 'faktury-online' | 'faktura-wzor') => {
    return (!!url && Boolean(articlesData[url]));
}