import { config } from "../../../../config/config";
import Cookies from 'js-cookie';
import { User } from '../RegisterService/Register.types';

export interface LoginCredentials {
    user: {
        email: string;
        password: string;
    }
}

export const login = async (input: LoginCredentials) => {
    try {
        const response = await fetch(`${config.API_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
        });

        if (response.ok) {
            // Login successful
            const userAuthenticated = await response.json() as User;
            // Set the token in cookies for using in subsequent requests. set an expiration of 7 days
            Cookies.set('userId', userAuthenticated.userId, { expires: 7 })
            Cookies.set('userToken', userAuthenticated.token, { expires: 7 })
            return userAuthenticated;
        } else {
            // Login failed
            const errorData = await response.json();
            throw new Error(errorData.message);
        }

    } catch (error: unknown) {
        // Handle errors
        console.error('Error logging in:', (error as any).message);
        throw error;
    }
};