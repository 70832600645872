
// converts amounts to cents
// Example usage:
// toCents("123") // Output: 12300
// toCents("123.59") // Output: 12359
// toCents("123,456.78") // Output: 12345678
// toCents(123.342432) // Output: 12334
export const toCents = (dollarAmount: string | number): number => {
    // Remove commas if present and convert to string
    const amountString = typeof dollarAmount === 'number' ? dollarAmount.toString() : dollarAmount.replace(/,/g, '');

    // Convert string to number (handles cases with or without decimals)
    const amountInCents = parseFloat(amountString) * 100;

    // Round to avoid floating-point arithmetic issues
    return Math.round(amountInCents);
}

export const amountFromCents = (amount: number): string => {
    const result = amount / 100; // Divide by 100 to get dollars
    return (Math.round(result * 100) / 100).toFixed(2); // Ensure result is rounded to 2 decimal places
}

// 43.0000 becoems 43
// 43.023000 becomes 43.023
export const stripTrailingZeros = (input: string) => {
    // Convert input to a string to ensure we can work with it as text
    let str = input.toString();
  
    // Use a regular expression to remove trailing zeros and possibly a trailing dot
    str = str.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');
  
    return str;
  }

export const sum = (...numbers: (string | number)[]) :number => {
    return numbers.reduce<number>((total, num) => Number(total) + Number(num), 0);
  }