import { Sidebar } from "flowbite-react"
import { HiArrowSmRight, HiChartPie, HiShoppingBag, HiTable, HiUser } from "react-icons/hi"



export const DashboardSidebar = () => {
    return (
        <Sidebar aria-label="Dashboard Sidebar">
            <Sidebar.Items>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/dashboard" icon={HiChartPie}>
                        Pulpit główny
                    </Sidebar.Item>
                    <Sidebar.Collapse icon={HiShoppingBag} label="Faktury">
                        <Sidebar.Item href="/dashboard/invoices">Wszystkie</Sidebar.Item>
                        <Sidebar.Item href="/dashboard/invoices/create">Nowa</Sidebar.Item>
                        {/* <Sidebar.Item href="#">Sales</Sidebar.Item>
                        <Sidebar.Item href="#">Refunds</Sidebar.Item>
                        <Sidebar.Item href="#">Shipping</Sidebar.Item> */}
                    </Sidebar.Collapse>
                    <Sidebar.Item href="#" icon={HiUser}>
                        Users
                    </Sidebar.Item>
                    <Sidebar.Item href="#" icon={HiShoppingBag}>
                        Products
                    </Sidebar.Item>
                    <Sidebar.Item href="#" icon={HiArrowSmRight}>
                        Sign In
                    </Sidebar.Item>
                    <Sidebar.Item href="#" icon={HiTable}>
                        Sign Up
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    );
}
