import { Link, useNavigate } from "react-router-dom"
import { Button, Checkbox, Label, Modal, ModalProps, TextInput } from "flowbite-react";
import { register } from "../services/RegisterService/Register.service";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createInvoiceRequest } from "../../invoice/services/createInvoiceService/createInvoice.service";
import { setUser } from "../UserSlice";

export const RegisterModal = (props: ModalProps) => {

    const [userData, setUserData] = useState({ email: '', password: '' });
    const invoice = useAppSelector(state => state.invoice);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const handleRegisterButtonClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        const hasUnsavedInvoice = invoice?.number && !invoice?.id;
        const registerResponse = await register({ user: userData, plan: 1 })
        if (registerResponse.token) {
            // save user store
            dispatch(setUser(registerResponse));
            if (hasUnsavedInvoice) {
                console.log('now on to submit that invoice...');
                const createInvoiceResponse = await createInvoiceRequest({ invoice: invoice, token: registerResponse.token })
                console.log('invoice create response ' + JSON.stringify(createInvoiceResponse));
            }
            navigate('/dashboard')
        }
    }

    return (
        <Modal show={props.show} onClose={props.onClose} size="md">
            <Modal.Header>Rejestracja</Modal.Header>

            <Modal.Body>
                <div className="space-y-6 mb-4">
                    <p className="text-sm leading-relaxed text-center">
                        Załóż konto w Instafakt i zyskaj możliwość zapisywania faktur, drukowania, pobierania na dysk, wysyłania mailem i wiele więcej.
                    </p>
                </div>

                <div>
                    <div className="mb-2 mt-6 block">
                        <Label htmlFor="email" value="Email:" />
                    </div>
                    <TextInput
                        id="email"
                        placeholder=""
                        required
                        shadow
                        type="email"
                        color="blue"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    />
                </div>
                <div>
                    <div className="mb-2 mt-6 block">
                        <Label htmlFor="password" value="Hasło" />
                    </div>
                    <TextInput
                        id="password"
                        required
                        shadow
                        type="password"
                        color="blue"
                        value={userData.password}
                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}

                    />
                </div>
                <div className="flex items-center gap-2 mt-7 mb-5">
                    <Checkbox id="agree" />
                    <Label className="flex" htmlFor="agree" >
                        <p className="text-xs">
                            Akceptuję
                        </p>
                        <Link className="text-blue-600 hover:underline dark:text-blue-500 px-1" to="#">
                            <p className="text-xs"> regulamin i politykę prywatności
                            </p>
                        </Link>
                    </Label>
                </div>
                <Button color="blue" fullSized={true} onClick={handleRegisterButtonClick}>Załóż konto</Button>
            </Modal.Body>
        </Modal>
    )

}