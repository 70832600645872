import * as React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HomePage } from './App';
import { Article } from './components/article/Article';
import { InvoiceForm } from './components/invoice/Invoice';
import { DashboardInvoiceList } from './components/dashboard/InvoiceList/InvoiceList';
import { store } from './store';
import { Provider } from 'react-redux';
import { DashboardHome } from './components/dashboard/Home/DashboardHome';
import { RegisterPage } from './components/auth/RegisterPage/RegisterPage';
import { LoginPage } from './components/auth/LoginPage/LoginPage';
import { Dashboard } from './components/dashboard/Dashboard';
import { usePageTracking } from './config/usePageTracking';
import { DashboardInvoice } from './components/dashboard/Invoice/DashboardInvoice';
import { PricingPage } from './components/pricingpage/PricingPage';
import { DashboardInvoiceView } from './components/dashboard/Invoice/DashboardInvoiceView';
import { ArticleList } from './components/article/ArticleList/ArticleList';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/rejestracja",
    element: <RegisterPage />,
  },
  {
    path: "/logowanie",
    element: <LoginPage />,
  },
  {
    path: "/cennik",
    element: <PricingPage />,
  },
  {
    path: "/faktura-formularz",
    element: <InvoiceForm />,
  },
  {
    path: "/article",
    element: <ArticleList />,
  },
  {
    path: "/article/:title",
    element: <Article />,
  },
  {
    path: "/dashboard/*",
    element: <Dashboard />,
    children: [
      {
        path: "home",
        element: <DashboardHome />,
      },
      {
        path: "invoices",
        element: <DashboardInvoiceList />,
      },
      {
        path: "invoices/create",
        element: <DashboardInvoice />,
      },
      {
        path: "invoices/:id",
        element: <DashboardInvoiceView />,
      },
    ]
  },


], { basename: "/" },);


const App = () => {
  usePageTracking();

  return (
    <Provider store={store}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  );
}
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw Error('No root element.');
}
ReactDOM.createRoot(rootElement).render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


