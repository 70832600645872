

export interface HeaderSectionArgs {
    heading: string;
    subheading?: string;
}

export const HeaderSection: React.FC<HeaderSectionArgs> = ({heading, subheading}) => {
    return (
        <div className="py-16 px-4 text-center bg-gradient-to-r">
            <h1 className="text-4xl font-bold mb-4 leading-tight tracking-tight text-gray-900">{heading}</h1>
            { subheading && <p className="text-lg">{subheading}</p> }
        </div>
    );
}