import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Invoice } from "./Invoice.types";

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {} as Invoice ,
    reducers: {
        addInvoice: (state, action: PayloadAction<Invoice>) => {
            return action.payload;
        }
    } 
});

export  const { addInvoice } = invoiceSlice.actions;
export default invoiceSlice.reducer; 